/**
 * Write-only the password as cookie
 */
import React, { useState, useRef, useEffect } from 'react';
import { Layout } from '../layouts/layout';

const styles = {
  wrapper: {
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
};

const LoginForm = (props) => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [info, setInfo] = useState('');
  const userInput = useRef(null);

  useEffect(() => {
    userInput.current.focus();
  }, []);

  async function postData(url = '', data = {}) {
    // Default options are marked with *
    console.log('postData', data.get('username'));
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: data, // body data type must match "Content-Type" header
    });
    let jsonResponse = null;
    try {
      jsonResponse = response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      jsonResponse = { success: false };
    }
    return jsonResponse;
  }

  const onSubmit = (event) => {
    event.preventDefault();

    var formData = new URLSearchParams(new FormData());
    formData.append('username', username);
    formData.append('password', password);

    postData('/login', formData).then((data) => {
      console.log('response', data);
      if (data.success) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const page = urlParams.get('page') || '';
        window.location.replace(`/private${page}`);
      } else {
        setInfo('Incorrect username or password.');
      }
    });
  };

  const showInfo = () => {
    return info ? <div className="mt3">{info}</div> : null;
  };

  return (
    <div style={styles.wrapper}>
      <h2>Login</h2>

      <form onSubmit={onSubmit} style={{ width: '320px' }} className="mv4">
        <div id="un" className="mv3">
          <label htmlFor="username" className="montserrat db fw6 lh-copy f6">
            Username
          </label>
          <input
            name="username"
            id="username"
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            className="montserrat pa2 input-reset ba bg-transparent w-100"
            ref={userInput}
          />
        </div>

        <div id="pd" className="mv3">
          <label htmlFor="password" className="montserrat db fw6 lh-copy f6">
            Password
          </label>
          <input
            name="password"
            id="password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className="montserrat pa2 input-reset ba bg-transparent w-100"
          />
        </div>

        {showInfo()}

        <button
          type="submit"
          className="mt3 pa2 b b--none w-100 submit-button montserrat"
        >
          Enter
        </button>
      </form>
    </div>
  );
};

const PasswordProtect = () => {
  return (
    <Layout>
      <LoginForm id={'login'} />
    </Layout>
  );
};

export default PasswordProtect;
